import React from 'react'
import {Provider} from 'react-redux'
import {Router} from 'react-router-dom'
import {CacheProvider, ThemeProvider} from '@emotion/react'
import Settings from 'Settings'
import {history} from 'utils/history'
import {InitializeABTesting} from 'widgets/InitializeABTesting'

import {DeviceLayoutProvider} from '@daedalus/atlas/context/deviceLayout'
import GridGlobalStyles from '@daedalus/atlas/foundation/GridGlobalStyles'
import {CssBaseline} from '@daedalus/atlas/helpers/CssBaseline'
import FontsLoader from '@daedalus/atlas/helpers/FontsLoader'
import {getTheme} from '@daedalus/atlas/themes'
import {getBrandTheme} from '@daedalus/core/src/_web/brand/modules/selectors'
import InitializeDataDogClient from '@daedalus/core/src/analytics/components/DataDogClient'
import {brands} from '@daedalus/core/src/brand/config'
import OpticksProvider from '@daedalus/core/src/experiments/components/OpticksProvider'
import fallbackDataFile from '@daedalus/core/src/experiments/modules/fallbackDataFile'
import ConfigureI18n from '@daedalus/core/src/localization/components/ConfigureI18n'
import LanguageConfig from '@daedalus/core/src/localization/components/LanguageConfig'
import {getTextDirection} from '@daedalus/core/src/localization/services/locale'
import {evaluateInRuntime} from '@daedalus/core/src/utils/css/emotion-rtl'

import configureStore from './configureStore'
import Routes from './routes'

const App = () => {
  const store = configureStore({})
  const locale = 'us'
  const state = store.getState()
  const brandTheme = getBrandTheme(state)

  const websiteTheme = getTheme(brandTheme)
  const {isRtl} = getTextDirection(locale)

  return (
    <Provider store={store}>
      <InitializeDataDogClient
        clientToken={Settings.get('REACT_APP_DD_CLIENT_TOKEN')}
        appVersion={Settings.get('REACT_APP_VERSION')}
        appEnv={Settings.get('REACT_APP_ENV')}
        applicationId={Settings.get('REACT_APP_DD_RUM_APP_ID')}
        sampleRate={Number(Settings.get('REACT_APP_DD_SAMPLE_RATE'))}
        premiumSampleRate={Number(
          Settings.get('REACT_APP_DD_REPLAY_SAMPLE_RATE')
        )}
        service="partners"
      />

      <OpticksProvider
        dataFileUrl={Settings.get('REACT_APP_OPTIMIZELY_DATAFILE_URL')}
        fallbackDataFile={fallbackDataFile}
        disableOptimizelyLogs={process.env.NODE_ENV === 'production'}
      >
        <InitializeABTesting>
          <ConfigureI18n languageCode={locale} countryCode={'US'}>
            <ThemeProvider theme={websiteTheme}>
              <DeviceLayoutProvider>
                <LanguageConfig locale={'us'} />
                <CacheProvider value={evaluateInRuntime(isRtl)}>
                  <GridGlobalStyles />
                  <CssBaseline />
                  <FontsLoader brand={brands.vio} languageCode={locale} />
                  <Router history={history}>
                    <Routes />
                  </Router>
                </CacheProvider>
              </DeviceLayoutProvider>
            </ThemeProvider>
          </ConfigureI18n>
        </InitializeABTesting>
      </OpticksProvider>
    </Provider>
  )
}

export default App
